import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TemplateListView from './views/templateListView';
import TemplateDetailView from './views/templateDetailView';
import TemplateRejectedView from './views/templateRejectedView'; // Importa TemplateRejectedView
import AnalyticsView from './views/analyticsView';
import './index.css';
import './i18n'; 
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import TemplateCreateView from './views/templateCreateView'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// cargar el url desde el archivo de variables de entorno .env
const BASE_URL = process.env.REACT_APP_BASE_URL_PHP;

const App = () => {
  const { i18n } = useTranslation();
  const [isSessionLoaded, setIsSessionLoaded] = useState(false);
  const [isSessionValid, setIsSessionValid] = useState(false);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    } else {
      sessionStorage.setItem('language', 'es'); // Idioma predeterminado
      i18n.changeLanguage('es');
    }
  }, [i18n]);

  const fetchSessionData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/assets/utils/session_handler.php`, { withCredentials: true });
      if (response.data.success) {
        const sessionToken = response.data.idcompany_vs;
        sessionStorage.setItem('token', sessionToken);
        sessionStorage.setItem('idcompany', response.data.idcompany_vs);
        setIsSessionValid(true);
      } else {
        console.error('Session data fetch error:', response.data);
        setIsSessionValid(false);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
      setIsSessionValid(false);
    } finally {
      setIsSessionLoaded(true);
    }
  };

  useEffect(() => {
    fetchSessionData();
  }, []);

  if (!isSessionLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<TemplateListView />} />
          <Route path="/template/:templateId" element={<TemplateDetailView />} />
          <Route path="/template/rejected/:templateId" element={<TemplateRejectedView />} /> {/* Nueva ruta para templates rechazados */}
          <Route path="/template/create" element={<TemplateCreateView />} />
          <Route path="/analytics" element={<AnalyticsView />} /> {/* Ruta para AnalyticsView */}
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
