import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Button, Dialog, DialogPanel } from '@tremor/react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { useTranslation } from 'react-i18next';

const TemplateDetailView = () => {
  const { templateId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [templateDetails, setTemplateDetails] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [totalClicks, setTotalClicks] = useState(0);
  const [buttonClicks, setButtonClicks] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [jsonOutput, setJsonOutput] = useState('');
  const [accountId, setAccountId] = useState(null);
  const [phoneIDs, setPhoneIDs] = useState([]); // Nuevo estado para los IDs de teléfono

  const BASE_URL = process.env.REACT_APP_BASE_URL_PHP;
  const token = sessionStorage.getItem('token') || '';

  // Obtener el lenguaje de la plantilla desde el estado
  const templateLanguage = location.state?.language || 'es';

  useEffect(() => {
    const fetchTemplateDetails = async () => {
      if (!token || !templateId) {
        setError(t('error.fetchDetails'));
        setLoading(false);
        return;
      }

      try {
        // Solicitar los detalles de la plantilla
        const detailResponse = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php`, {
          params: {
            request_type: 'template_detail',
            template_id: templateId,
            token: token,
          },
        });
        setTemplateDetails(detailResponse.data);

        // Solicitar las estadísticas de la plantilla
        const statsResponse = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php`, {
          params: {
            request_type: 'template_analytics',
            template_ids: templateId,
            token: token,
          },
        });
        const statsData = statsResponse.data?.data_points || [];
        setStatistics(statsData);
        //console.log("Datos crudos del backend:", statsData);

        let clicks = 0;
        let buttonData = {};

        statsData.forEach(stat => {
          const clickedData = stat.clicked || [];
          clicks += clickedData.reduce((sum, click) => sum + click.count, 0);
          clickedData.forEach(click => {
            buttonData[click.button_content] = (buttonData[click.button_content] || 0) + click.count;
          });
        });

        setTotalClicks(clicks);
        setButtonClicks(buttonData);
      } catch (err) {
        setError(t('error.fetchDetails'));
        console.error('Error fetching data:', err);
      }
      setLoading(false);
    };

    const fetchAccountId = async () => {
      try {
        const accountResponse = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php`, {
          params: {
            request_type: 'get_account_id',
            token: token,
          },
        });
        if (accountResponse.data?.accountId) {
          setAccountId(accountResponse.data.accountId);
        } else {
          console.error('Error fetching accountId:', accountResponse.data?.error);
        }
      } catch (err) {
        console.error('Error fetching accountId:', err);
      }
    };

    const fetchPhoneIDs = async () => {
      try {
        const phoneResponse = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php`, {
          params: {
            request_type: 'fetch_phone_numbers',
            token: token,
          },
        });
        if (phoneResponse.data?.phone_number_ids) {
          setPhoneIDs(phoneResponse.data.phone_number_ids);
        }
      } catch (err) {
        console.error('Error fetching phone IDs:', err);
      }
    };

    // Ejecutar todas las solicitudes en paralelo
    fetchTemplateDetails();
    fetchAccountId();
    fetchPhoneIDs();
  }, [templateId, token, BASE_URL, t]);

  const generateJson = () => {
    if (!templateDetails) return;

    const components = [];
    const phoneVariable = t('variablephone'); 

    // Procesar HEADER
    const headerComponent = templateDetails.components.find(comp => comp.type === 'HEADER');
    if (headerComponent && headerComponent.format === 'IMAGE' && headerComponent.example?.header_handle) {
      components.push({
        type: 'header',
        parameters: [
          {
            type: 'image',
            image: {
              link: "$s{url_image}",
            },
          },
        ],
      });
    }

    // Procesar BODY y extraer placeholders
    const bodyComponent = templateDetails.components.find(comp => comp.type === 'BODY');
    if (bodyComponent && bodyComponent.text) {
      const placeholders = bodyComponent.text.match(/{{\d+}}/g) || [];
      const uniquePlaceholders = [...new Set(placeholders)];

      const bodyParameters = uniquePlaceholders.map((placeholder, index) => ({
        type: 'text',
        text: `$s{variable_${index + 1}}`,
      }));

      components.push({
        type: 'body',
        parameters: bodyParameters,
      });
    }

    // Procesar BUTTONS
    const buttonsComponent = templateDetails.components.find(comp => comp.type === 'BUTTONS');
    if (buttonsComponent && buttonsComponent.buttons) {
      buttonsComponent.buttons.forEach((button, idx) => {
        if (button.type === 'URL' && /{{\d+}}/.test(button.url)) {
          const dynamicUrl = button.url.replace(/{{\d+}}/, `$s{cta_url_${idx + 1}}`);
          
          components.push({
            type: 'button',
            sub_type: 'url',
            index: idx,
            parameters: [
              { type: 'text', text: dynamicUrl }
            ]
          });
        }
      });
    }

    // Construcción del JSON final
    const jsonTemplate = {
      messaging_product: "whatsapp",
      to: `$s{${phoneVariable}}`,
      type: "template",
      template: {
        name: templateDetails.name,
        language: {
          code: templateLanguage,
          policy: "deterministic",
        },
        components: components,
      },
    };

    setJsonOutput(JSON.stringify(jsonTemplate, null, 2));
    setDialogVisible(true);
  };

  const copyJsonAndClose = () => {
    if (!jsonOutput || phoneIDs.length === 0) return;

    // Generar todos los endpoints para cada Phone ID
    const endpointUrls = phoneIDs.map(phoneId => 
      `https://graph.facebook.com/v19.0/${phoneId}/messages`
    );

    const contentToCopy = endpointUrls.map((url, index) => `Endpoint Phone ID ${index + 1}: ${url}`).join('\n') + `\n\n${jsonOutput}`;

    navigator.clipboard.writeText(contentToCopy)
      .then(() => {
        alert(t('alert.jsonCopied'));
        setDialogVisible(false);
      })
      .catch(err => {
        console.error('Error copying JSON:', err);
        alert(t('alert.copyFailed'));
      });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img width={350} src="/spinner.gif" alt={t('loading')} />
      </div>
    );
  }

  if (error) return <p className="text-red-500">{error}</p>;

  const sentCount = statistics.reduce((sum, stat) => sum + (stat.sent || 0), 0);
  const deliveredCount = statistics.reduce((sum, stat) => sum + (stat.delivered || 0), 0);
  const failedCount = sentCount - deliveredCount;
  const readCount = statistics.reduce((sum, stat) => sum + (stat.read || 0), 0);
  const notReadCount = deliveredCount - readCount;

  const readPercentage = deliveredCount ? ((readCount / deliveredCount) * 100).toFixed(2) : 0;
  const notReadPercentage = deliveredCount ? ((notReadCount / deliveredCount) * 100).toFixed(2) : 0;

  const donutData = [
    { name: t('analytics.delivered'), value: Number(deliveredCount ? ((deliveredCount / sentCount) * 100).toFixed(2) : 0) },
    { name: t('analytics.failed'), value: Number(sentCount ? ((failedCount / sentCount) * 100).toFixed(2) : 0) },
  ];

  const percentageData = [
    { name: t('analytics.read'), value: Number(readPercentage) },
    { name: t('analytics.notRead'), value: Number(notReadPercentage) },
  ];

  const prepareChartData = () => {
    const startDate = new Date(Date.now() - 89 * 24 * 60 * 60 * 1000); // Hace 89 días
    const endDate = new Date();
  
    // Crear un mapa de estadísticas por fecha
    const statsMap = statistics.reduce((acc, stat) => {
      const date = new Date(stat.start * 1000); // Convertir timestamp a fecha
      const formattedDate = date.toISOString().split('T')[0]; // Mantener en formato YYYY-MM-DD (UTC)
  
      acc[formattedDate] = {
        sent: stat.sent || 0,
        delivered: stat.delivered || 0,
        read: stat.read || 0,
      };
      return acc;
    }, {});
  
    // Rellenar fechas faltantes con valores predeterminados
    const filledData = [];
    let currentDate = new Date(startDate);
  
    while (currentDate <= endDate) {
      const formattedDate = currentDate.toISOString().split('T')[0]; // Formato UTC
  
      filledData.push({
        date: formattedDate,
        sent: statsMap[formattedDate]?.sent || 0,
        delivered: statsMap[formattedDate]?.delivered || 0,
        read: statsMap[formattedDate]?.read || 0,
      });
  
      currentDate.setUTCDate(currentDate.getUTCDate() + 1); // Avanzar al siguiente día en UTC
    }
  
    return filledData;
  };
  
  
  const handleDownloadCSV = () => {
    const csvData = [];
    csvData.push([t('analytics.startDate'), t('analytics.sent'), t('analytics.delivered'), t('analytics.read'), t('button.totalClicks')]);
  
    const clicksPerButton = {};
    statistics.forEach(stat => {
      // Convertir el timestamp a una fecha en UTC
      const date = new Date(stat.start * 1000).toISOString().split('T')[0]; // Formato YYYY-MM-DD en UTC
      const totalClicksForStat = stat.clicked ? stat.clicked.reduce((sum, click) => sum + click.count, 0) : 0;
  
      csvData.push([
        date,
        stat.sent || 0,
        stat.delivered || 0,
        stat.read || 0,
        totalClicksForStat,
      ]);
  
      if (stat.clicked) {
        stat.clicked.forEach(click => {
          clicksPerButton[click.button_content] = (clicksPerButton[click.button_content] || 0) + click.count;
        });
      }
    });
  
    csvData.push(['', '', '', '', '']);
    csvData.push([t('button.totalClicks')]);
    for (const button in clicksPerButton) {
      csvData.push([button, clicksPerButton[button]]);
    }
  
    const csvString = csvData.map(row => row.join(',')).join('\n');
  
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'template_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button
          className="text-xl mr-4 text-blue-500 hover:text-blue-700"
          onClick={() => navigate('/')}
        >
          &#8592;
        </button>
        <h1 className="text-2xl font-bold">{t('templates.templateDetailTitle')}</h1>
        <Button variant="secondary" onClick={handleDownloadCSV} className="text-blue-500 hover:text-blue-700 ml-4">
          {t('button.downloadCSV')}
        </Button>
      </div>

      <Dialog open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <DialogPanel>
          <h2 className="text-xl font-bold mb-2">{t('modal.jsonTitle')}</h2>
          {phoneIDs.length > 0 && (
            <div className="mb-4">
              {phoneIDs.map((phoneId, index) => (
                <p key={index}>
                  <strong>{`Endpoint Phone ID ${index + 1}:`}</strong> https://graph.facebook.com/v19.0/{phoneId}/messages
                </p>
              ))}
            </div>
          )}
          <pre className="bg-gray-100 p-4 rounded overflow-x-auto">{jsonOutput}</pre>
          <Button onClick={copyJsonAndClose} className="mt-4 bg-blue-500 text-white hover:bg-blue-700">
            {t('button.copyAndClose')}
          </Button>
        </DialogPanel>
      </Dialog>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="col-span-1">
          <Card className="mb-4"><Button variant="secondary" onClick={generateJson} className="mb-4 text-blue-500 hover:text-blue-700">
                {t('button.showJson')}
              </Button>
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4">{t('templates.name')}: {templateDetails?.name}</h2>
              
            </div>
            <p className="text-sm text-gray-600 mb-2"><strong>{t('templates.language')}: </strong>{templateLanguage}</p>
            {templateDetails ? (
              <div><p className="text-sm text-gray-600 mb-4">
              <strong>{t('templates.category')}:</strong> {templateDetails?.category || 'N/A'}
            </p>
                {templateDetails.components?.map((component, index) => (
                  <div key={index} className="mb-2">
                    <p><strong>{component.type}:</strong> {component.text }</p>
                    {component.type === 'HEADER' && component.example?.header_handle && (
                      <img src={component.example.header_handle[0]} alt="Header Example" className="my-2" />
                    )}
                    {component.type === 'BUTTONS' && component.buttons?.map((button, idx) => (
                      <a key={idx} href={button.url} target="_blank" rel="noopener noreferrer" className="inline-block bg-slate-100 hover:bg-slate-200 text-slate font-bold py-2 px-4 rounded m-1">
                        {button.text}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            ) : <p>{t('templates.noData')}</p>}
          </Card>
        </div>

        <div className="col-span-2">
          <div className="grid grid-cols-5 gap-4 mb-4">
            <Card>
              <h4 className="font-bold">{t('analytics.sent')}</h4>
              <p className="text-2xl">{sentCount}</p>
            </Card>
            <Card>
              <h4 className="font-bold">{t('analytics.delivered')}</h4>
              <p className="text-2xl">{deliveredCount}</p>
            </Card>
            <Card>
              <h4 className="font-bold">{t('analytics.read')}</h4>
              <p className="text-2xl">{readCount}</p>
            </Card>

            <Card className="col-span-2">
              <h4 className="font-bold">{t('button.totalClicks')}: {totalClicks}</h4>
              {Object.entries(buttonClicks).length > 0 ? (
                <ul>
                  {Object.entries(buttonClicks).map(([buttonContent, count]) => (
                    <li key={buttonContent}>
                      <strong>{buttonContent}:</strong> {count}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{t('button.noClicks')}</p>
              )}
            </Card>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <Card>
              <h4 className="font-bold">{t('analytics.readAndNotRead')}</h4>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={percentageData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={30}
                    label
                  >
                    {percentageData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={index === 0 ? "#82ca9d" : "#ffc658"} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Card>

            <Card>
              <h4 className="font-bold">{t('analytics.deliveredAndFailed')}</h4>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={donutData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={30}
                    label
                  >
                    {donutData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={index === 0 ? "#82ca9d" : "#ff7300"} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </div>

          <Card>
            <h2 className="text-xl font-bold mb-4">{t('analytics.chartTitle')}</h2>
            {statistics.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={prepareChartData()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="sent" stroke="#8884d8" name={t('analytics.sent')} />
                  <Line type="monotone" dataKey="delivered" stroke="#82ca9d" name={t('analytics.delivered')} />
                  <Line type="monotone" dataKey="read" stroke="#ffc658" name={t('analytics.read')} />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <p>{t('templates.noData')}</p>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TemplateDetailView;
