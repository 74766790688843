import React, { useState, useEffect, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';
import axios from 'axios';
import { 
  Card, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Button, 
  Badge, 
  TextInput,
  Label
} from '@tremor/react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { 
  ResponsiveContainer, 
  LineChart, 
  Line, 
  CartesianGrid, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend 
} from 'recharts';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import {
    ArrowLeftIcon,
  } from '@heroicons/react/24/solid';

// Load the URL from the .env environment variables
const BASE_URL = process.env.REACT_APP_BASE_URL_PHP;

const TemplateListView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem('token') || "");
  const [idCompany, setIdCompany] = useState(sessionStorage.getItem('idcompany') || "");
  const [isTokenSet, setIsTokenSet] = useState(!!token);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(sessionStorage.getItem('language') || 'es');
  
  // State for search
  const [searchQuery, setSearchQuery] = useState('');

  //console.log("Token in sessionStorage listview:", sessionStorage.getItem('token'));
  //console.log("REACT_APP_BASE_URL_PHP in listview:", process.env.REACT_APP_BASE_URL_PHP);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    sessionStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  // Debounced search function
  const debouncedSetSearchQuery = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [] // Create a single debounce instance
  );

  const handleSearchChange = (e) => {
    debouncedSetSearchQuery(e.target.value);
  };

  const fetchData = async (action = 'load_initial', url = null) => {
    setLoading(true);
    let fetchUrl = url;

    if (!url) {
      // Construct the initial URL
      fetchUrl = `${BASE_URL}/src/controllers/integrated_controller.php?request_type=template_list&action=${action}&token=${token}`;
    }

    //console.log(`Fetching data with URL: ${fetchUrl}`); // Log for debugging

    try {
      const response = await axios.get(fetchUrl);
     //console.log("Response data:", response.data); // Log the response data

      if (response.data.error) {
        setError(response.data.error);
        setData([]);
        setNextUrl(null);
        setPreviousUrl(null);
      } else {
        setData(response.data.data || []);
        setNextUrl(response.data.paging?.next || null);
        setPreviousUrl(response.data.paging?.previous || null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(t('error.loadingTemplates'));
      setData([]);
      setNextUrl(null);
      setPreviousUrl(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchAnalyticsData = async () => {
    const formattedStartDate = Math.floor(startDate.getTime() / 1000);
    const formattedEndDate = Math.floor(endDate.getTime() / 1000);

    try {
      const response = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php?request_type=analytics&start=${formattedStartDate}&end=${formattedEndDate}&token=${token}`);
      const fetchedData = response.data.analytics.data_points.map(point => ({
        date: new Date(point.start * 1000).toLocaleDateString(),
        sent: point.sent,
        delivered: point.delivered
      }));
      setAnalyticsData(fetchedData);
    } catch (err) {
      setError(t('error.loadingAnalytics'));
    }
  };

  useEffect(() => {
    if (!token || !idCompany) {
      setError("Session not valid.");
      return;
    }
    
    setIsTokenSet(true);
    fetchData(); // Load the first page
    fetchAnalyticsData();

    // Clean up the debounce on component unmount
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [token, startDate, endDate, idCompany]);

  const totalSent = analyticsData.reduce((sum, item) => sum + (item.sent || 0), 0);
  const totalDelivered = analyticsData.reduce((sum, item) => sum + (item.delivered || 0), 0);

  // Filter templates based on the search query
  const filteredData = data.filter(template => {
    const query = searchQuery.toLowerCase();
    return (
      template.name.toLowerCase().includes(query) ||
      template.language.toLowerCase().includes(query) ||
      template.category.toLowerCase().includes(query)
    );
  });

  return (
    <div className="container mx-auto p-4">
      {error && <p className="text-red-500">{error}</p>}

      {/* Analytics Section */}
      <Card className="mb-6">
        
        <div className="flex  items-center mb-4"><button
            onClick={() => {
              sessionStorage.removeItem('templateData');
              navigate('/');
            }}
            className="text-xl mr-4 text-blue-500 hover:text-blue-700"
          >
            <ArrowLeftIcon className="h-5 w-5 inline" />
          </button>
          <h2 className="text-xl font-bold">{t('analytics.title')}</h2>
          {/* <Button
            onClick={() => navigate('/template/create')}
            className="bg-green-500 text-white"
          >
            + {t('button.addNewTemplate')}
          </Button> */}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <Card className="p-4">
            <h3 className="font-bold mb-4">{t('analytics.selectDateRange')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm">{t('analytics.startDate')}</label>
                <DatePicker 
                  selected={startDate} 
                  onChange={setStartDate} 
                  maxDate={new Date()} 
                  className="date-picker border pt-1 pb-1 pl-1 pr-0 mb-2 text-xs" 
                />
              </div>
              <div>
                <label className="block text-sm">{t('analytics.endDate')}</label>
                <DatePicker 
                  selected={endDate} 
                  onChange={setEndDate} 
                  maxDate={new Date()} 
                  className="date-picker border pt-1 pb-1 pl-1 pr-0 mb-2 text-xs" 
                />
              </div>
            </div>
          </Card>
          <Card className="p-4">
            <h3 className="font-bold">{t('analytics.totalSent')}</h3>
            <p className="text-2xl">{totalSent}</p>
          </Card>
          <Card className="p-4">
            <h3 className="font-bold">{t('analytics.totalDelivered')}</h3>
            <p className="text-2xl">{totalDelivered}</p>
          </Card>
        </div>
        <Card className="mb-4 p-4">
          <h3 className="font-bold">{t('analytics.performance')}</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={analyticsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sent" stroke="#8884d8" name={t('analytics.sent')} />
              <Line type="monotone" dataKey="delivered" stroke="#82ca9d" name={t('analytics.delivered')} />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Card>

     
    </div>
  );
};

export default TemplateListView;
