import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button } from '@tremor/react';
import { useTranslation } from 'react-i18next';

const TemplateRejectedView = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [templateDetails, setTemplateDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const BASE_URL = process.env.REACT_APP_BASE_URL_PHP;
  const token = sessionStorage.getItem('token') || '';

  useEffect(() => {
    const fetchTemplateDetails = async () => {
      if (!token || !templateId) {
        setError(t('error.fetchDetails'));
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php`, {
          params: {
            request_type: 'template_detail',
            template_id: templateId,
            token: token,
          },
        });
        setTemplateDetails(response.data);
      } catch (err) {
        setError(t('error.fetchDetails'));
        console.error('Error fetching template details:', err);
      }
      setLoading(false);
    };

    fetchTemplateDetails();
  }, [templateId, token, BASE_URL, t]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img width={350} src="/spinner.gif" alt={t('loading')} />
      </div>
    );
  }

  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-4 flex justify-center">
      <div className="flex items-center mb-4">
        <button
          className="text-xl mr-4 text-blue-500 hover:text-blue-700"
          onClick={() => navigate('/')}
        >
          &#8592;
        </button>
        <div>
          <h1 className="text-2xl font-bold">{t('templates.templateRejectedTitle')}</h1>
          {templateDetails?.rejected_reason && (
            <p className="text-sm text-red-500 mt-2">
              <strong>{t('templates.rejectedReason')}:</strong> {templateDetails.rejected_reason}
            </p>
          )}
        </div>
      </div>
  
      <Card className="p-4 max-w-[350px] mx-auto">
        <h2 className="text-xl font-bold mb-4">{t('templates.name')}: {templateDetails?.name}</h2>
        <p className="text-sm text-gray-600 mb-2">
          <strong>{t('templates.language')}:</strong> {templateDetails?.language || 'N/A'}
        </p>
        <p className="text-sm text-gray-600 mb-4">
          <strong>{t('templates.category')}:</strong> {templateDetails?.category || 'N/A'}
        </p>
  
        {templateDetails?.components?.map((component, index) => (
          <div key={index} className="mb-2">
            <p><strong>{component.type}:</strong> {component.text}</p>
            {component.type === 'HEADER' && component.example?.header_handle && (
              <img src={component.example.header_handle[0]} alt="Header Example" className="my-2" />
            )}
            {component.type === 'BUTTONS' && component.buttons?.map((button, idx) => (
              <a
                key={idx}
                href={button.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-slate-100 hover:bg-slate-200 text-slate font-bold py-2 px-4 rounded m-1"
              >
                {button.text}
              </a>
            ))}
          </div>
        ))}
      </Card>
    </div>
  );
  
};

export default TemplateRejectedView;
