// TemplateListView.js
import React, { useState, useEffect, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';
import axios from 'axios';
import { 
  Card, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Button, 
  Badge, 
  TextInput,
  Label
} from '@tremor/react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { 
  ResponsiveContainer, 
  LineChart, 
  Line, 
  CartesianGrid, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend 
} from 'recharts';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
// No es necesario importar AnalyticsView aquí si es una vista separada

// Load the URL from the .env environment variables
const BASE_URL = process.env.REACT_APP_BASE_URL_PHP;

const TemplateListView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem('token') || "");
  const [idCompany, setIdCompany] = useState(sessionStorage.getItem('idcompany') || "");
  const [isTokenSet, setIsTokenSet] = useState(!!token);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(sessionStorage.getItem('language') || 'es');
  
  // Estado para la búsqueda
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  //console.log("Token in sessionStorage listview:", sessionStorage.getItem('token'));
  //console.log("REACT_APP_BASE_URL_PHP in listview:", process.env.REACT_APP_BASE_URL_PHP);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    sessionStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  // Función de búsqueda con debounce
  const debouncedSetSearchQuery = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [] // Crear una única instancia de debounce
  );

  const handleSearchChange = (e) => {
    debouncedSetSearchQuery(e.target.value);
  };

  // Función para obtener datos
  const fetchData = async (action = 'load_initial', url = null, query = '') => {
    setLoading(true);
    let fetchUrl = url;

    if (query) {
      // Si hay una consulta de búsqueda, incluirla en la URL
      fetchUrl = `${BASE_URL}/src/controllers/integrated_controller.php?request_type=template_list&search_query=${encodeURIComponent(query)}&token=${token}`;
      setIsSearching(true);
    } else if (!url) {
      // Construir la URL inicial
      fetchUrl = `${BASE_URL}/src/controllers/integrated_controller.php?request_type=template_list&action=${action}&token=${token}`;
      setIsSearching(false);
    }

   // console.log(`Fetching data with URL: ${fetchUrl}`); // Log para depuración

    try {
      const response = await axios.get(fetchUrl);
      //console.log("Response data:", response.data); // Log de la respuesta

      if (response.data.error) {
        setError(response.data.error);
        setData([]);
        setNextUrl(null);
        setPreviousUrl(null);
      } else {
        if (query) {
          // Si es una búsqueda, no hay paginación
          setData(response.data.data || []);
          setNextUrl(null);
          setPreviousUrl(null);
        } else {
          // Manejo de paginación normal
          setData(response.data.data || []);
          setNextUrl(response.data.paging?.next || null);
          setPreviousUrl(response.data.paging?.previous || null);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(t('error.loadingTemplates'));
      setData([]);
      setNextUrl(null);
      setPreviousUrl(null);
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener datos de analytics
  const fetchAnalyticsData = async () => {
    const formattedStartDate = Math.floor(startDate.getTime() / 1000);
    const formattedEndDate = Math.floor(endDate.getTime() / 1000);

    try {
      const response = await axios.get(`${BASE_URL}/src/controllers/integrated_controller.php?request_type=analytics&start=${formattedStartDate}&end=${formattedEndDate}&token=${token}`);
      const fetchedData = response.data.analytics.data_points.map(point => ({
        date: new Date(point.start * 1000).toLocaleDateString(),
        sent: point.sent,
        delivered: point.delivered
      }));
      setAnalyticsData(fetchedData);
    } catch (err) {
      setError(t('error.loadingAnalytics'));
    }
  };

  // useEffect para cargar datos iniciales y analytics
  useEffect(() => {
    if (!token || !idCompany) {
      setError("Session not valid.");
      return;
    }
    
    setIsTokenSet(true);
    fetchData(); // Cargar la primera página
    fetchAnalyticsData();

    // Limpiar debounce al desmontar el componente
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [token, startDate, endDate, idCompany]);

  // useEffect para manejar cambios en searchQuery
  useEffect(() => {
    if (searchQuery.trim() !== '') {
      fetchData('load_initial', null, searchQuery.trim());
    } else {
      fetchData(); // Cargar datos sin búsqueda
    }
  }, [searchQuery]);

  const totalSent = analyticsData.reduce((sum, item) => sum + (item.sent || 0), 0);
  const totalDelivered = analyticsData.reduce((sum, item) => sum + (item.delivered || 0), 0);

  return (
    <div className="container mx-auto p-4">
      {error && <p className="text-red-500">{error}</p>}

      {/* Sección de Plantillas */}
      <Card>
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
          {/* Lado izquierdo: Botón para agregar plantilla y enlace a Analytics */}
          <div className="flex items-center space-x-4">
            <Button
              onClick={() => navigate('/template/create')}
              className="bg-green-500 text-white border-green-600"
            >
              + {t('button.addNewTemplate')}
            </Button>
            <Link 
              to="/analytics" // Asegúrate de que esta ruta esté configurada
              className="text-gray-500 hover:text-gray-700"
            >
              {t('linkAnalytics')} {/* Define esta clave en tus archivos de traducción */}
            </Link>
          </div>

          {/* Lado derecho: Contenedor de búsqueda */}
          <div className="flex items-center space-x-2 mt-4 md:mt-0">
            <TextInput
              type="search"
              placeholder={t('templates.searchPlaceholder')}
              onChange={handleSearchChange}
              className="w-64 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FiSearch className="text-gray-500" />
          </div>
        </div>

        {loading ? (
          <p>{t('loading')}</p>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='font-bold'>{t('templates.name')}</TableCell>
                <TableCell className='font-bold'>{t('templates.language')}</TableCell>
                <TableCell className='font-bold'>{t('templates.category')}</TableCell>
                <TableCell className='font-bold'>{t('templates.bodyText')}</TableCell>
                <TableCell className='font-bold'>{t('templates.status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((template, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {template.status === 'REJECTED' ? (
                        <Link 
                          to={`/template/rejected/${template.id}`}
                          state={{ language: template.language }}
                          className="text-indigo-500 hover:underline"
                          // onClick={() => console.log("Navigating to details with language:", template.language)}
                          >
                          {template.name}
                        </Link>
                      ) : (
                        <Link 
                          to={`/template/${template.id}`}
                          state={{ language: template.language }} // Pass language in state
                          className="text-indigo-600 hover:underline"
                          //onClick={() => console.log("Navigating to details with language:", template.language)}
                        >
                          {template.name}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>{template.language}</TableCell>
                    <TableCell>{template.category}</TableCell>
                    <TableCell>
                      {template.components.find(component => component.type === 'BODY').text.substring(0, 30) + '...'}
                    </TableCell>
                    <TableCell>
                      {template.status === 'APPROVED' ? (
                        <Badge className="dark:ring-none bg-green-50 text-green-600 dark:bg-green-200 dark:text-white">{t('templates.approved')}</Badge>
                      ) : template.status === 'REJECTED' ? (
                        <Badge className="bg-red-50 text-red-600">{t('templates.rejected')}</Badge> 
                      ) : (
                        <Badge variant="warning">{template.status}</Badge>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="5">{t('templates.noData')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}

        {/* Botones de Paginación */}
        {!isSearching && (
          <div className="flex mt-4 gap-4">
            {previousUrl && (
              <Button 
                onClick={() => fetchData('load_previous', previousUrl)} 
                variant='secondary'
              >
                {t('button.previousPage')}
              </Button>
            )}
            {nextUrl && (
              <Button 
                onClick={() => fetchData('load_more', nextUrl)} 
                variant='secondary'
              >
                {t('button.nextPage')}
              </Button>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default TemplateListView;
