import React, { useState, useEffect } from 'react';
import { Card, Button, Select, SelectItem, TextInput, Textarea, Flex } from '@tremor/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  PhoneIcon,
  LinkIcon,
  ListBulletIcon,
} from '@heroicons/react/24/solid';
import ErrorBoundary from './ErrorBoundary';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Obtener el token de la sesión
const token = sessionStorage.getItem('token') || '';
const BASE_URL = process.env.REACT_APP_BASE_URL_PHP;

const TemplateCreateView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Lista de países de las Américas con sus códigos
  const americanCountries = [
    { name: 'United States', code: '1' },
    { name: 'Canada', code: '1' },
    { name: 'Mexico', code: '52' },
    { name: 'Brazil', code: '55' },
    { name: 'Argentina', code: '54' },
    { name: 'Colombia', code: '57' },
    { name: 'Chile', code: '56' },
    { name: 'Peru', code: '51' },
    { name: 'Venezuela', code: '58' },
    { name: 'Uruguay', code: '598' },
    { name: 'Paraguay', code: '595' },
  ];

  const [templateData, setTemplateData] = useState({
    category: 'UTILITY',
    name: '',
    language: 'es',
    header: {
      type: 'NONE',
      content: '',
      image: null,
      imageHandler: null, // imageHandler para el API de Facebook
    },
    body: '',
    footer: '',
    quickReplyButtons: [],
    callToActionButtons: [],
    sampleTexts: {},
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState('');
  const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
  const [jsonToShow, setJsonToShow] = useState('');

  useEffect(() => {
    renumberVariables();
  }, [templateData.body]);

  const renumberVariables = () => {
    const regex = /{{\d+}}/g;
    const variablesInBody = templateData.body.match(regex) || [];
    const uniqueVariables = [...new Set(variablesInBody)];

    const mapping = {};
    uniqueVariables.forEach((oldVar, index) => {
      const newVar = `{{${index + 1}}}`;
      mapping[oldVar] = newVar;
    });

    let newBody = templateData.body;
    Object.keys(mapping).forEach(oldVar => {
      const newVar = mapping[oldVar];
      const regexVar = new RegExp(oldVar, 'g');
      newBody = newBody.replace(regexVar, newVar);
    });

    const newSampleTexts = {};
    uniqueVariables.forEach((oldVar, index) => {
      const newVar = `{{${index + 1}}}`;
      newSampleTexts[newVar] = templateData.sampleTexts[oldVar] || '';
    });

    setTemplateData(prevState => ({
      ...prevState,
      body: newBody,
      sampleTexts: newSampleTexts,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'body') {
      newValue = value.replace(/(\r?\n){3,}/g, '\n\n');
    }

    setTemplateData({
      ...templateData,
      [name]: newValue,
    });

    if (name === 'name' && value.trim() !== '') {
      setError('');
    }
  };

  const handleSelectChange = (name, value) => {
    setTemplateData({
      ...templateData,
      [name]: value,
    });
  };

  const handleHeaderChange = (e) => {
    const { name, value } = e.target;
    setTemplateData(prevState => ({
      ...prevState,
      header: {
        ...prevState.header,
        [name]: value,
      },
    }));
  };

  // Función para subir imagen 
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTemplateData(prevState => ({
          ...prevState,
          header: {
            ...prevState.header,
            image: reader.result, // Mostrar imagen en base64 para previsualización
          },
        }));
      };
      reader.readAsDataURL(file);
      uploadImageToBackend(file);
    }
  };

  const uploadImageToBackend = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("token", token);
    formData.append("request_type", "upload_image");
    
    try {
      const response = await fetch(`${BASE_URL}/src/controllers/upload_image.php`, {
       // const response = await fetch(`${BASE_URL}/src/controllers/integrated_controller.php`, {
        method: 'POST',
        body: formData
      });
  
      // Verifica si la respuesta es exitosa
      if (!response.ok) {
        const errorText = await response.text(); // Obtener la respuesta de error como texto
        console.error('Error en la respuesta del servidor:', errorText);
        toast.error('Error en la respuesta del servidor'); // Notificar al usuario
        return;
      }
  
      const result = await response.json(); // Intenta parsear la respuesta como JSON
  
      if (result.image_handler) {
        setTemplateData((prevData) => ({
          ...prevData,
          header: {
            ...prevData.header,
            imageHandler: result.image_handler,
          },
        }));
        toast.success('Imagen cargada exitosamente');
      } else {
        toast.error('Error al cargar la imagen');
      }
    } catch (error) {
      console.error('Error en la solicitud de imagen:', error);
      toast.error('Error en la solicitud de imagen');
    }
  };
  
  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTemplateData(prevState => ({
          ...prevState,
          header: {
            ...prevState.header,
            video: reader.result,
            image: null,
            type: 'VIDEO',
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const addVariable = () => {
    const nextNumber = Object.keys(templateData.sampleTexts).length + 1;
    const newVariable = `{{${nextNumber}}}`;
    const newBody = `${templateData.body} ${newVariable}`.trim();

    setTemplateData(prevState => ({
      ...prevState,
      body: newBody,
      sampleTexts: {
        ...prevState.sampleTexts,
        [newVariable]: '',
      },
    }));
  };

  const removeVariable = (variable) => {
    const regex = new RegExp(variable, 'g');
    let newBody = templateData.body.replace(regex, '').replace(/\s{2,}/g, ' ').trim();

    const updatedSampleTexts = { ...templateData.sampleTexts };
    delete updatedSampleTexts[variable];

    setTemplateData(prevState => ({
      ...prevState,
      body: newBody,
      sampleTexts: updatedSampleTexts,
    }));

    renumberVariables();
  };

  const updateSampleText = (key, value) => {
    setTemplateData(prevState => ({
      ...prevState,
      sampleTexts: {
        ...prevState.sampleTexts,
        [key]: value,
      },
    }));
  };

  const addQuickReplyButton = () => {
    setTemplateData(prevState => ({
      ...prevState,
      quickReplyButtons: [
        ...prevState.quickReplyButtons,
        { label: `Button ${prevState.quickReplyButtons.length + 1}` },
      ],
    }));
  };

  const addCallToActionButton = (type) => {
    if (
      type === 'PHONE' &&
      templateData.callToActionButtons.filter(btn => btn.type === 'PHONE_NUMBER').length === 0
    ) {
      setTemplateData(prevState => ({
        ...prevState,
        callToActionButtons: [
          ...prevState.callToActionButtons,
          {
            type: 'PHONE_NUMBER',
            label: 'Call Phone',
            country: '', // Solo el código numérico del país
            phoneNumber: '',
          },
        ],
      }));
    } else if (
      type === 'URL' &&
      templateData.callToActionButtons.filter(btn => btn.type === 'URL').length < 2
    ) {
      setTemplateData(prevState => ({
        ...prevState,
        callToActionButtons: [
          ...prevState.callToActionButtons,
          {
            type: 'URL',
            label: `Website ${prevState.callToActionButtons.filter(btn => btn.type === 'URL').length + 1}`,
            url: '',
            mode: 'static', // Mantener el modo para determinar si es estático o dinámico
            example: '',
          },
        ],
      }));
    }
  };

  const updateButtonLabel = (index, type, value) => {
    const buttons =
      type === 'quickReply'
        ? [...templateData.quickReplyButtons]
        : [...templateData.callToActionButtons];
    buttons[index].label = value;

    if (type === 'quickReply') {
      setTemplateData(prevState => ({ ...prevState, quickReplyButtons: buttons }));
    } else {
      setTemplateData(prevState => ({ ...prevState, callToActionButtons: buttons }));
    }
  };

  const updateButtonFunction = (index, value, field) => {
    const buttons = [...templateData.callToActionButtons];
    if (buttons[index].type === 'URL') {
      // Manejo de botones URL
      if (field === 'URL') {
        buttons[index].url = value;
      } else if (field === 'MODE') {
        buttons[index].mode = value;
        if (value === 'static') {
          buttons[index].example = ''; // Limpiar el ejemplo si es estático
        }
      } else if (field === 'EXAMPLE') {
        buttons[index].example = value;
      }
    } else if (buttons[index].type === 'PHONE_NUMBER') {
      if (field === 'PHONE_NUMBER') {
        const sanitizedPhoneNumber = value.replace(/\D/g, '');
        buttons[index].phoneNumber = sanitizedPhoneNumber;
      } else if (field === 'COUNTRY') {
        buttons[index].country = value;
      }
    }

    setTemplateData(prevState => ({
      ...prevState,
      callToActionButtons: buttons,
    }));
  };

  const removeButton = (index, type) => {
    if (type === 'quickReply') {
      const updatedButtons = templateData.quickReplyButtons.filter((_, i) => i !== index);
      setTemplateData(prevState => ({ ...prevState, quickReplyButtons: updatedButtons }));
    } else {
      const updatedButtons = templateData.callToActionButtons.filter((_, i) => i !== index);
      setTemplateData(prevState => ({ ...prevState, callToActionButtons: updatedButtons }));
    }
  };

  const transformToWhatsAppJSON = () => {
    const components = [];
  
    // Componente HEADER
    if (templateData.header.type === 'IMAGE' && templateData.header.imageHandler) {
      components.push({
        type: 'HEADER',
        format: 'IMAGE',
        example: {
          header_handle: [templateData.header.imageHandler], // Usamos un array con el header_handle
        },
      });
    }
  
    // Componente BODY
    components.push({
      type: 'BODY',
      text: templateData.body,
      example: {
        body_text: [
          Object.values(templateData.sampleTexts).map((value) => value || ''),
        ],
      },
    });
  
    // Componente FOOTER
    if (templateData.footer) {
      components.push({
        type: 'FOOTER',
        text: templateData.footer,
      });
    }
  
    // Componente BUTTONS
    const buttons = [];
  
    // Agregar botones de tipo QUICK_REPLY
    templateData.quickReplyButtons.forEach(button => {
      buttons.push({
        type: "QUICK_REPLY",
        text: button.label,
      });
    });
  
    // Agregar botones de tipo CALL_TO_ACTION
    templateData.callToActionButtons.forEach(button => {
      if (button.type === 'URL') {
        const dynamicUrl = button.mode === 'dynamic' ? `${button.url}{{1}}` : button.url;
  
        const buttonObject = {
          type: "URL",
          text: button.label,
          url: dynamicUrl,
        };
  
        // Agregar 'example' solo si el URL es dinámico
        if (button.mode === 'dynamic') {
          buttonObject.example = [`${button.url}${button.example || ''}`];
        }
  
        buttons.push(buttonObject);
      } else if (button.type === 'PHONE_NUMBER') {
        const sanitizedPhoneNumber = button.phoneNumber.replace(/\D/g, '');
        const fullPhoneNumber = `${button.country}${sanitizedPhoneNumber}`;
  
        buttons.push({
          type: "PHONE_NUMBER",
          text: button.label,
          phone_number: fullPhoneNumber,
        });
      }
    });
  
    if (buttons.length > 0) {
      components.push({
        type: "BUTTONS",
        buttons,
      });
    }
  
    const whatsappTemplateJSON = {
      name: templateData.name.trim().replace(/\s+/g, '_').toLowerCase(),
      language: templateData.language,
      category: templateData.category,
      components,
    };
  
    return whatsappTemplateJSON;
  };
  
  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar que el nombre no esté vacío
    if (templateData.name.trim() === '') {
      toast.error(t('templates.nameRequired')); // Notificar al usuario
      return;
    }

    // Validar textos de ejemplo vacíos
    const emptySampleTexts = Object.values(templateData.sampleTexts).some((value) => value.trim() === '');

    if (emptySampleTexts) {
      toast.error(t('templates.sampleTextsRequired')); // Notificar al usuario
      return;
    }

    try {
      const whatsappTemplateJSON = transformToWhatsAppJSON();

      const jsonData = {
        request_type: 'create_template',
        token: token, // Asegurarse de incluir el token aquí
        whatsapp_template: whatsappTemplateJSON,
      };

     //console.log("JSON Data to Send:", JSON.stringify(jsonData, null, 2)); // Log de depuración
      // Aquí puedes añadir el manejo para mostrar el JSON antes de enviar

      // Enviar el JSON a la API para crear el template
      confirmSubmit(jsonData);
    } catch (error) {
      toast.error(error.message); // Notificar al usuario de errores inesperados
    }
  };

  const confirmSubmit = async (jsonData) => {
    setIsJsonModalOpen(false);
    
    try {
      const response = await fetch(
        `${BASE_URL}/src/controllers/integrated_controller.php`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonData), // Enviar como JSON
          credentials: 'include',
        }
      );

      const result = await response.json();
      //console.log("Resultado del controlador:", result);

      if (response.ok && result.data && result.data.id) {
        toast.success(`Éxito: ${result.success}\nID de Plantilla: ${result.data.id}`);
        navigate('/');
      } else {
        let errorMessage = 'Ocurrió un error al crear la plantilla.';
        if (result.error_user_msg) {
          errorMessage = result.error_user_msg;
        } else if (result.error && result.error.error_user_msg) {
          errorMessage = result.error.error_user_msg;
        } else if (result.error_message) {
          errorMessage = result.error_message;
        } else if (result.error) {
          errorMessage = result.error;
        }
        toast.error(`Error: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      toast.error('Ocurrió un error inesperado al crear la plantilla.');
    }
  };

  const renderPreview = () => {
    const allButtons = [
      ...templateData.quickReplyButtons,
      ...templateData.callToActionButtons,
    ];

    const previewBody = templateData.body.replace(/{{\d+}}/g, (match) => {
      return templateData.sampleTexts[match] || match;
    });

    return (
      <Card
        className="w-1/4 p-4 border border-gray-300 rounded-lg shadow mt-10 fixed top-4 right-4 bg-amber-50 gap-6"
        style={{ zIndex: 10 }}
      >
        <div className="mb-4 font-bold">{t('templatePreviewTitle')}</div>
        <div className="w-[285px] bg-white p-1 rounded-lg shadow">
          {templateData.header.type === 'IMAGE' && templateData.header.image && (
            <img
              src={templateData.header.image}
              alt="Uploaded"
              className="w-full h-32 object-cover rounded-lg mb-2"
              style={{ borderRadius: '5px' }}
            />
          )}
          {templateData.header.type === 'TEXT' && templateData.header.content.trim() !== '' && (
            <p className="font-bold pl-1">{templateData.header.content}</p>
          )}
          <p className="pl-1">{previewBody}</p>
          {templateData.footer && (
            <p className="text-gray-400 text-xs mt-1 pl-1">
              {templateData.footer}
            </p>
          )}
          <div className="mt-4 text-center">
            {allButtons.slice(0, 2).map((button, index) => (
              <div
                key={index}
                className="border-b py-2 flex items-center justify-center"
              >
                {button.type === 'QUICK_REPLY' && (
                  <ListBulletIcon className="h-5 w-5 mr-2 text-cyan-500" />
                )}
                {button.type === 'PHONE_NUMBER' && (
                  <PhoneIcon className="h-5 w-5 mr-2 text-cyan-500" />
                )}
                {button.type === 'URL' && (
                  <LinkIcon className="h-5 w-5 mr-2 text-cyan-500" />
                )}
                <p className="text-cyan-500 text-ms">{button.label}</p>
              </div>
            ))}
            {allButtons.length > 2 && (
              <div className="border-b py-2 flex items-center justify-center">
                <ListBulletIcon className="h-5 w-5 mr-2 text-cyan-500" />
                <p className="text-cyan-500 text-ms">
                  {t('templates.seeAllOptions')}
                </p>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className="container mx-auto p-4 flex">
      <div className="w-2/3">
        <div className="flex items-center mb-4">
          <button
            onClick={() => {
              sessionStorage.removeItem('templateData');
              navigate('/');
            }}
            className="text-xl mr-4 text-blue-500 hover:text-blue-700"
          >
            <ArrowLeftIcon className="h-5 w-5 inline" />
          </button>
          <h2 className="text-xl font-bold">{t('templates.goBackTemplateList')}</h2>
        </div>
        <Card>
          <div className="flex items-center mb-4">
            <h2 className="text-xl font-bold">{t('templates.createNewTemplate')}</h2>
          </div>
          <form onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <>
                <div className="mb-4">
                  <label className="block mb-2">
                    {t('templates.chooseCategory')}
                  </label>
                  <ErrorBoundary>
                    <Select
                      name="category"
                      value={templateData.category}
                      onValueChange={(value) => handleSelectChange('category', value)}
                      required
                    >
                      <SelectItem value="MARKETING" text="Marketing" />
                      <SelectItem value="UTILITY" text="Utility" />
                    </Select>
                  </ErrorBoundary>
                </div>
                <div className="mb-4">
                  <label className="block mb-2">{t('templates.name')}</label>
                  <TextInput
                    name="name"
                    value={templateData.name}
                    onChange={handleChange}
                    required
                    maxLength={512}
                    placeholder="Template Name"
                  />
                  <p className="text-gray-400 text-xs">Máximo 512 caracteres</p>
                  {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                </div>
                <div className="mb-4">
                  <label className="block mb-2">
                    {t('templates.selectLanguage')}
                  </label>
                  <ErrorBoundary>
                    <Select
                      name="language"
                      value={templateData.language}
                      onValueChange={(value) => handleSelectChange('language', value)}
                      required
                    >
                      <SelectItem value="es" text="Spanish" />
                      <SelectItem value="en" text="English" />
                    </Select>
                  </ErrorBoundary>
                </div>
                <Button
                  type="button"
                  onClick={() => {
                    if (templateData.name.trim() === '') {
                      setError(t('templates.nameRequired'));
                      toast.error(t('templates.nameRequired')); // Notificar al usuario
                    } else {
                      setError('');
                      setCurrentStep(2);
                    }
                  }}
                  className={`bg-blue-500 text-white mt-4 ${
                    templateData.name.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={templateData.name.trim() === ''}
                >
                  {t('button.next')}
                </Button>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className="mb-4">
                  <label className="block mb-2 font-bold mt-8">
                    {t('templates.header')}
                  </label>
                  <ErrorBoundary>
                    <Select
                      name="header.type"
                      value={templateData.header.type}
                      onValueChange={(value) =>
                        handleHeaderChange({
                          target: { name: 'type', value },
                        })
                      }
                    >
                      <SelectItem value="NONE" text="None" />
                      <SelectItem value="TEXT" text="Text" />
                      <SelectItem value="IMAGE" text="Image" />
                      {/* <SelectItem value="VIDEO" text="Video" /> */}
                    </Select>
                  </ErrorBoundary>
                  {templateData.header.type === 'TEXT' && (
                    <TextInput
                      name="content"
                      value={templateData.header.content}
                      onChange={handleHeaderChange}
                      placeholder={t('templates.headerContent')}
                      maxLength={60}
                      className="mt-2"
                    />
                  )}
                  {templateData.header.type === 'IMAGE' && (
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="mt-2"
                    />
                  )}
                  {templateData.header.type === 'VIDEO' && (
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleVideoUpload}
                      className="mt-2"
                    />
                  )}
                  <p className="text-gray-400 text-xs">Máximo 60 caracteres</p>
                </div>
                <div className="mb-4">
                  <label className="block mb-2 font-bold mt-8">{t('templates.body')}</label>
                  <Textarea
                    name="body"
                    value={templateData.body}
                    onChange={handleChange}
                    required
                    placeholder={t('templates.enterText')}
                    maxLength={1024}
                  />
                  <p className="text-gray-400 text-xs">Máximo 1024 caracteres</p>
                  <div className="flex items-center mt-2">
                    <Button
                      type="button"
                      onClick={addVariable}
                      variant="secondary"
                      className="bg-indigo-500 text-white mt-2"
                    >
                      + {t('button.addVariable')}
                    </Button>
                  </div>
                </div>
                {Object.keys(templateData.sampleTexts).length > 0 && (
                  <div className="mb-4">
                    <h3 className="font-bold mt-10">{t('SamplesBodyContentTitle')}</h3>
                    <p className="text-gray-500 text-sm mb-2">{t('SamplesBodyContentInstruction')}</p>
                    {Object.keys(templateData.sampleTexts).map((key, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <p className="text-gray-600">{key}</p>
                        <TextInput
                          value={templateData.sampleTexts[key]}
                          onChange={(e) => updateSampleText(key, e.target.value)}
                          placeholder={`Enter content for ${key}`}
                          className="ml-2"
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className="mb-4 mt-10">
                  <label className="block mb-2 font-bold">
                    {t('templates.footer')}
                  </label>
                  <TextInput
                    name="footer"
                    value={templateData.footer}
                    onChange={handleChange}
                    maxLength={60}
                    placeholder="Footer"
                  />
                  <p className="text-gray-400 text-xs">Máximo 60 caracteres</p>
                </div>
                <div className="mb-4 mt-8">
                  <h3 className="font-bold">{t('templates.quickReplyButtons')}</h3>
                  <p className="text-gray-500 text-sm mb-2">{t('templates.buttonsInstruction')}</p>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={addQuickReplyButton}
                    className="bg-indigo-500 text-white mt-2"
                  >
                    {t('templates.addQuickReply')}
                  </Button>
                  {templateData.quickReplyButtons.map((button, index) => (
                    <div key={index} className="mt-2 flex items-center">
                      <TextInput
                        value={button.label}
                        onChange={(e) =>
                          updateButtonLabel(index, 'quickReply', e.target.value)
                        }
                        placeholder="Button Text"
                      />
                      <Button
                        type="button"
                        onClick={() => removeButton(index, 'quickReply')}
                        variant="secondary"
                        className="text-indigo-500 mt-1 ml-2"
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
                <div className="mb-4 mt-8">
                  <h3 className="font-bold">{t('templates.callToActionButtons')}</h3>
                 
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => addCallToActionButton('URL')}
                    className="bg-indigo-500 text-white mt-2"
                  >
                    {t('templates.addWebsiteButton')}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => addCallToActionButton('PHONE')}
                    className="bg-indigo-500 text-white mt-2 ml-2"
                  >
                    {t('templates.addPhoneButton')}
                  </Button>
                  {templateData.callToActionButtons.map((button, index) => (
                    <Flex key={index} className="flex-col space-y-4">
                      <Flex className="space-x-4 mt-8 items-center">
                        {/* Component A: Dropdown de Static/Dynamic para botones URL */}
                        {button.type === 'URL' && (
                          <Select
                            value={button.mode}
                            onValueChange={(value) => {
                              updateButtonFunction(index, value, 'MODE');
                            }}
                            className="w-1/4"
                          >
                            <SelectItem value="static" text="Static" />
                            <SelectItem value="dynamic" text="Dynamic" />
                          </Select>
                        )}

                        {/* Component B: Input de Texto del Botón */}
                        <TextInput
                          value={button.label}
                          onChange={(e) =>
                            updateButtonLabel(index, 'callToAction', e.target.value)
                          }
                          placeholder="Button Text"
                          className="flex-1"
                        />

                        {/* Component C: Input de URL del Sitio Web (solo para URLs) */}
                        {button.type === 'URL' && (
                          <TextInput
                            value={button.url}
                            onChange={(e) =>
                              updateButtonFunction(index, e.target.value, 'URL')
                            }
                            placeholder="Website URL (e.g., https://www.example.com?promo={{1}})"
                            className="flex-2"
                          />
                        )}

                        {/* Component D: Input de Contenido de Ejemplo (solo para URLs dinámicas) */}
                        {button.type === 'URL' && button.mode === 'dynamic' && (
                          <>
                            <TextInput
                              value={button.example}
                              onChange={(e) =>
                                updateButtonFunction(index, e.target.value, 'EXAMPLE')
                              }
                              placeholder="Dynamic value for URL"
                              className="flex-1"
                            />
                          </>
                        )}

                        <Button
                          type="button"
                          onClick={() => removeButton(index, 'callToAction')}
                          variant="secondary"
                          className=""
                        >
                          Remove
                        </Button>
                      </Flex>
                      {button.type === 'URL' && button.mode === 'dynamic' && (
                        <>
                          {/* Previsualización de la URL Dinámica */}
                          <div className="ml-2 mt-2">
                            <p className="text-sm text-gray-500">
                              {t('templates.finalUrl')}: {button.url}{button.example}
                            </p>
                          </div>
                        </>
                      )}

                      {/* Component G: Inputs para Números de Teléfono */}
                      {button.type === 'PHONE_NUMBER' && (
                        <Flex className="space-x-2">
                          {/* Dropdown de Código de País */}
                          <Select
                            onValueChange={(value) =>
                              updateButtonFunction(index, value, 'COUNTRY')
                            }
                            placeholder="Country Code"
                            className="w-1/4"
                          >
                            {americanCountries.map((country, idx) => (
                              <SelectItem
                                key={idx}
                                value={`${country.code}`}
                                text={`${country.code} ${country.name}`}
                              />
                            ))}
                          </Select>

                          <TextInput
                            value={button.phoneNumber}
                            onChange={(e) =>
                              updateButtonFunction(index, e.target.value, 'PHONE_NUMBER')
                            }
                            placeholder="Phone Number"
                            className="w-3/4"
                          />
                        </Flex>
                      )}
                    </Flex>
                  ))}
                </div>
                <Button
                  type="button"
                  onClick={() => setCurrentStep(1)}
                  className="bg-gray-500 text-white mt-4 mr-2"
                >
                  {t('button.previous')}
                </Button>
                <Button
                  type="submit"
                  className="bg-indigo-500 text-white mt-4"
                >
                  {t('button.submit')}
                </Button>
              </>
            )}
          </form>
        </Card>
      </div>
      {renderPreview()}
      <ToastContainer 
        className="Toastify__toast-container--center" // Clase personalizada
        position="top-center" 
        autoClose={10000}        // Tiempo en milisegundos antes de cerrar automáticamente (10 segundos)
        hideProgressBar={false} // Muestra la barra de progreso
        newestOnTop={false}     // Orden de aparición
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"         // Tema del toast
      />
      {isJsonModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg max-w-3xl w-full overflow-auto">
            <h2 className="text-xl font-bold mb-4">JSON to Send</h2>
            <pre className="bg-gray-100 p-4 rounded overflow-auto max-h-96">
              {jsonToShow}
            </pre>
            <div className="mt-4 flex justify-end">
              <Button
                onClick={() => setIsJsonModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </Button>
              <Button
                onClick={() => confirmSubmit(jsonToShow)} // Asegúrate de que confirmSubmit esté correctamente definida
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateCreateView;
