import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // Pasar i18n a react-i18next
  .init({
    resources: {
      en: {
        translation: {

          "linkAnalytics":"Go to Analytics",
          "welcome": "Welcome",
          "loading": "Loading...",
          "SamplesBodyContentTitle" : "Samples for Body Content",
          "SamplesBodyContentInstruction" : "To help us review your message template, please add an example for each variable in your body text. Do not use real customer information.",
          "templatePreviewTitle": "Template Preview",
          "variablephone" : "phone",
          "input": {
            "companyId": "Enter the company ID to continue",
            "placeholder": "Enter company ID"
          },
          "button": {
            "confirmToken": "Confirm Token",
            "loadMore": "Load More",
            "downloadCSV": "Download CSV",
            "totalClicks": "Total Clicks in Buttons",
            "noClicks": "No button clicks available.",
            "addVariable": "Add Variable",
            "submit": "Submit",
            "next": "Next",
            "previous": "Previous",
            "addQuickReply": "Add Quick Reply Button",
            "addWebsiteButton": "Add Website Button",
            "addPhoneButton": "Add Phone Button",
            "noData": "No data found",
            "addNewTemplate" : "New Template",
            "showJson" : "Show JSON",
            "copyAndClose": "Copy and Close",
            "nextPage" : "Next page",
            "previousPage" : "Previous page"
          },
          "analytics": {
            "title": "Analytics for the selected time period",
            "selectDateRange": "Select Date Range:",
            "startDate": "Start Date:",
            "endDate": "End Date:",
            "totalSent": "Total Sent",
            "totalDelivered": "Total Delivered",
            "performance": "Performance in the period",
            "sent": "Sent",
            "delivered": "Delivered",
            "read": "Read",
            "notRead": "Not Read",
            "deliveredAndFailed": "Delivered % and Failed %",
            "readAndNotRead": "Read % and Not Read %",
            "chartTitle": "Chart for the Last 90 Days",
          },
          "templates": {
        
            "rejectedReason":"Reject Reason",
            "title": "WhatsApp Templates for this Account",
            "name": "Template Name",
            "language": "Language",
            "category": "Category",
            "bodyText": "Body Text",
            "status": "Status",
            "approved": "APPROVED",
            "noData": "No data found",
            "template": "Template",
            "templateDetailTitle": "Template Analytics (90 days)",
            "templateRejectedTitle": "Template Rejected",
            "createNewTemplate": "Create New Template",
            "chooseCategory": "Choose the category that best describes your message template.",
            "selectLanguage": "Select Language",
            "header": "Header (Optional)",
            "body": "Body",
            "footer": "Footer (Optional)",
            "quickReplyButtons": "Quick Reply Buttons",
            "callToActionButtons": "Call to Action Buttons",
            "buttonsInstruction": "Create buttons that let customers respond to your message or take action. You can add up to 10 buttons. If you add more than 3 buttons, they will appear in a list.",
            "templatePreview": "Template Preview",
            "seeAllOptions": "See all options",
            "addQuickReply": "Add Quick Reply",
            "addWebsiteButton": "Add Website",
            "addPhoneButton": "Add Phone",
            "goBackTemplateList": "Go Back to Template List",
            "templatePreviewTitle": "Template Preview",
            "enterText": "Enter text here", // Agregada clave
            "headerContent": "Enter header content", // Agregada clave
            "rejected" : "REJECTED",
            "finalUrl" : "URL rendered",
            "sampleTextsRequired" :"Example texts for body are required fields.",
            "searchPlaceholder" : "Filter by name"
          },
          "error": {
            "loadingTemplates": "Error loading templates",
            "loadingAnalytics": "There is no data to show",
            "fetchDetails": "Error fetching template details"
          },
          "modal": {
            "jsonTitle": "JSON to send", // Presente
            "endpointUrl": "Endpoint URL" // **Nueva clave añadida**
          },
          "alert": { // **Nueva sección añadida**
            "jsonCopied": "JSON copied to clipboard.", // **Nueva clave añadida**
            "copyFailed": "Failed to copy JSON." // **Nueva clave añadida**
          }
        }
      },
      es: {
        translation: {
          "welcome": "Bienvenido",

          "linkAnalytics":" Ver Análitica",
          "loading": "Cargando...",
          "SamplesBodyContentTitle" : "Ejemplos del cuerpo de contenido",
          "SamplesBodyContentInstruction" : "Para ayudarnos a revisar tu plantilla de mensaje, por favor agrega un ejemplo para cada variable en el texto del cuerpo. No uses información real de clientes.",  
          "templatePreviewTitle": "Previsualización de la Plantilla",
          "variablephone" : "telefono",
          "input": {
            "companyId": "Ingresa el ID de la empresa para continuar",
            "placeholder": "Ingresa el ID de la empresa"
          },
          "button": {
            "confirmToken": "Confirmar Token",
            "loadMore": "Cargar más",
            "downloadCSV": "Descargar CSV",
            "totalClicks": "Total de Clics en Botones",
            "noClicks": "No hay clics disponibles por botón.",
            "addVariable": "Agregar Variable",
            "submit": "Enviar",
            "next": "Siguiente",
            "previous": "Anterior",
            "addQuickReply": "Agregar Botón de Respuesta Rápida",
            "addWebsiteButton": "Agregar Botón de Sitio Web",
            "addPhoneButton": "Agregar Botón de Teléfono",
            "noData": "No se encontraron datos",
            "addNewTemplate" : "Nueva plantilla",
            "showJson" : "Mostrar JSON",
            "copyAndClose": "Copiar y Cerrar",
            "nextPage" : "Página siguiente",
            "previousPage" : "Página anterior"
          },
          "analytics": {
            "title": "Analítica por período de tiempo",
            "selectDateRange": "Seleccione el rango de fechas:",
            "startDate": "Fecha de inicio:",
            "endDate": "Fecha de fin:",
            "totalSent": "Total Enviados",
            "totalDelivered": "Total Entregados",
            "performance": "Rendimiento en el período",
            "sent": "Enviados",
            "delivered": "Entregados",
            "read": "Leídos",
            "notRead": "No Leídos",
            "deliveredAndFailed": "Porcentaje de Recibidos y Fallidos",
            "readAndNotRead": "Porcentaje de Leídos y No Leídos",
            "chartTitle": "Gráfico de los últimos 90 días",
          },
          "templates": {
            "rejectedReason":"Razones del rechazo",
            "title": "Plantillas de WhatsApp de la Cuenta",
            "name": "Nombre de la Plantilla",
            "language": "Idioma",
            "category": "Categoría",
            "bodyText": "Texto del Cuerpo",
            "status": "Estado",
            "approved": "APROBADO",
            "noData": "No se encontraron datos",
            "template": "Plantilla",
            "templateDetailTitle": "Analíticas de la Plantilla (90 días)",
            "templateRejectedTitle": "Plantilla Rechazada",
            "createNewTemplate": "Crear Nueva Plantilla",
            "chooseCategory": "Elige la categoría que mejor describe tu plantilla de mensaje.",
            "selectLanguage": "Selecciona el Idioma",
            "header": "Encabezado (Opcional)",
            "body": "Cuerpo",
            "footer": "Pie de Página (Opcional)",
            "quickReplyButtons": "Botones de Respuesta Rápida",
            "callToActionButtons": "Botones de Llamada a la Acción",
            "buttonsInstruction": "Crea botones que permitan a los clientes responder a tu mensaje o realizar una acción. Puedes agregar hasta 10 botones. Si agregas más de 3 botones, aparecerán en una lista.",
            "templatePreview": "Vista Previa de la Plantilla",
            "seeAllOptions": "Ver todas las opciones",
            "addQuickReply": "Agregar Respuesta Rápida",
            "addWebsiteButton": "Agregar Sitio Web",
            "addPhoneButton": "Agregar Teléfono",
            "goBackTemplateList": "Volver al listado de plantillas",
            "templatePreviewTitle": "Template Preview",
            "enterText": "Ingresa el texto aquí", // Agregada clave
            "headerContent": "Ingresa el contenido del encabezado", // Agregada clave
            "rejected" : "RECHAZADO",
            "finalUrl" : "URL armado",
            "sampleTextsRequired" :"Los textos de ejemplo del cuerpo son requeridos",
            "searchPlaceholder" : "Filtrar por nombre"
          },
          "error": {
            "loadingTemplates": "Error al cargar las plantillas",
            "loadingAnalytics": "No hay data para mostrar",
            "fetchDetails": "Error al obtener los detalles de la plantilla"
          },
          "modal": {
            "jsonTitle": "Json para configurar el envío masivo", // Presente
            "endpointUrl": "Endpoint URL" // **Nueva clave añadida**
          },
          "alert": { // **Nueva sección añadida**
            "jsonCopied": "JSON copiado al portapapeles.", // **Nueva clave añadida**
            "copyFailed": "Error al copiar el JSON." // **Nueva clave añadida**
          }
        }
      }
    },
    lng: "es", // Idioma predeterminado
    fallbackLng: "es", // Idioma de respaldo
    interpolation: {
      escapeValue: false // React ya se encarga de esto
    }
  });

export default i18n;
